<template>
  <div class="email-container-display">
    <div class="email-top">
      <button type="button" @click="sendEmail">Send</button>
    </div>
    <div class="email-from-div">
      <div class="email-from-inside">
        <label for="email-from">From:</label>
        <input type="text" id="email-from" v-model="from" />
      </div>
    </div>
    <div class="email-to-div">
      <div class="email-to-inside">
        <label for="email-to">To:</label>
        <div
          class="recipient-container"
          :style="toStyle"
          @click="giveFocusToInput()"
        >
          <span v-for="(recipient, recipientIndex) in to" :key="recipient.key"
            >{{ recipient }}
            <span @click="to.splice(recipientIndex, 1)" class="delete-recipient"
              >x</span
            >
          </span>
          <input
            type="text"
            id="email-to"
            v-model="currentRecipient"
            @focus="toStyle = { borderColor: 'lightgrey' }"
            @blur="
              toStyle = '';
              addToRecipient();
            "
            @keypress.space.prevent="addToRecipient()"
          /><br />
        </div>
      </div>
    </div>
    <div class="email-subject-div">
      <div class="email-subject-inside">
        <label for="email-subject">Subject:</label>
        <input type="text" id="email-subject" v-model="subject" />
      </div>
    </div>
    <div class="email-writing-div">
      <textarea v-model="body" placeholder="Write your email"></textarea>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "Email",
  props: {
    currentTicketId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      from: "contact@travelassist.io",
      to: [],
      body: "",
      toStyle: "",
      currentRecipient: "",
      subject: "",
    };
  },
  created() {},
  methods: {
    giveFocusToInput: function () {
      document.getElementById("email-to").focus();
    },
    addToRecipient: function () {
      if (this.currentRecipient.trim() != "") {
        this.to.push(this.currentRecipient);
        this.currentRecipient = "";
      }
    },
    sendEmail: function () {
      if (this.to != []) {
        let toTransformed = "";
        this.to.forEach((element) => (toTransformed += element + ","));
        toTransformed = toTransformed.substring(0, toTransformed.length - 1);
        let data = {
          to: this.to,
          from: this.from,
          subject: this.subject,
          content: this.body,
        };
        if (this.$store.getters.userType == "contactCenterAgent") {
          data["agentId"] = this.$store.getters.userId;
        }
        HandleApi.sendEmail(this.currentTicketId, data)
          .then(() => {
            this.$emit("emailSent");
            this.$store.dispatch("push_notification_message", "Email sent");
          })
          .catch((err) => this.$store.dispatch("push_error_message", err));
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.email-container-display {
  background-color: white;
  border-radius: 20px;
  display: grid;
  grid-template-rows: 1.5fr 1fr 1fr 1fr 9fr;
}

.email-top {
  width: 100%;
  background-color: rgb(20, 20, 20);
}

.email-top button {
  float: right;
  margin-top: 1%;
  margin-right: 20px;
  background-color: #009fbc;
  border: 3px solid #009fbc;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
}

.email-top button:hover {
  border-color: white;
  cursor: pointer;
}

.email-from-div {
  background-color: white;
  padding: 10px;
  border-bottom: 2px solid grey;
}

.email-from-div input {
  width: 90%;
  border: none;
  border: 3px solid white;
}

.email-from-div input:hover {
  border-color: lightgrey;
}

.email-from-div input:focus {
  border-color: lightgrey;
}

.email-from-inside {
  margin-top: 10px;
  margin-bottom: 10px;
}

.email-to-div {
  background-color: white;
  padding: 10px;
  border-bottom: 2px solid grey;
}

.delete-recipient:hover {
  cursor: pointer;
}

.recipient-container {
  width: 90%;
  border: 3px solid white;
  display: inline-block;
  padding: 2px;
}

.recipient-container:hover {
  border-color: lightgrey;
}

.recipient-container:focus {
  border-color: lightgrey;
}

.recipient-container input {
  border: none;
}

.recipient-container span {
  border: none;
  border-radius: 10px;
  background-color: lightgrey;
  color: grey;
  padding-left: 5px;
  padding-right: 2px;
  display: inline-block;
  margin-right: 5px;
}

.email-to-inside {
  margin-top: 10px;
  margin-bottom: 10px;
}

.email-subject-div {
  background-color: white;
  padding: 10px;
  border-bottom: 2px solid grey;
}

.email-subject-div input {
  width: 90%;
  border: none;
  border: 3px solid white;
}

.email-subject-div input:hover {
  border-color: lightgrey;
}

.email-subject-div input:focus {
  border-color: lightgrey;
}

.email-subject-inside {
  margin-top: 10px;
  margin-bottom: 10px;
}

.email-writing-div {
  background-color: white;
  padding: 10px;
}

.email-writing-inside {
  margin-top: 10px;
  margin-bottom: 10px;
}

textarea {
  resize: none;
  height: calc(95% - 20px);
  width: calc(100% - 20px);
  overflow: auto;
  outline: none;
  border: 3px solid white;
  padding: 5px;
}

textarea:hover {
  border-color: lightgrey;
}

textarea:focus {
  border-color: lightgrey;
}
</style>
