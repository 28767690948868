<template>
  <div class="ticket-display-container">
    <div class="ticket-information-generic">
      <label>Status:</label>
      <button
        class="ticket-status-display"
        :style="{ backgroundColor: computeStyleStatus }"
        :disabled="true"
      >
        {{ ticket.status }}
      </button>
      <br />
      <label>Handled:</label>
      <span>{{ ticket.handled }}</span
      ><br />
      <label>Start Date:</label>
      <span>{{ new Date(ticket.startDate).toDateString() }}</span
      ><br />
      <label v-if="ticket.hasOwnProperty('endDate')">End Date:</label>
      <span v-if="ticket.hasOwnProperty('endDate')">{{
        new Date(ticket.endDate).toDateString()
      }}</span>
      <br />
      <label v-if="ticket.hasOwnProperty('typology')">Typology :</label><br />
      <span v-if="ticket.hasOwnProperty('typology')"
        >- {{ this.typology[0][ticket.typology.class] }}</span
      ><br />
      <span v-if="ticket.hasOwnProperty('typology')"
        >-
        {{
          this.typology[1][ticket.typology.class][ticket.typology.subClass]
        }}</span
      ><br />
      <span v-if="ticket.hasOwnProperty('typology')"
        >-
        {{
          this.typology[2][ticket.typology.class][ticket.typology.final]
        }}</span
      ><br />
      <label>Agent actions history:</label>
      <ul class="ticket-history">
        <li v-for="action in niceHistoryDisplay" :key="action.id">
          {{ Object(action.agentId).username }} ({{
            Object(action.agentId).contactCenter
          }}) -
          <span :style="{ color: 'white', backgroundColor: action.color }">{{
            action.name
          }}</span>
          |
          {{ $d(new Date(action.date), "long") }}
        </li>
      </ul>
      <br />
      <label>Evaluation</label><br />
      <label>Overview</label>
      <br />
      <input type="range" min="0" max="10" v-model="evaluation.notation" />
      <br />
      <label>Comments</label><br />
      <textarea
        placeholder="Write the tickets comments"
        v-model="evaluation.comment"
      ></textarea>
      <button
        type="button"
        class="save-evaluation-button"
        @click="saveEvaluation()"
      >
        Save
      </button>
    </div>
    <ticket-message
      :matrixConf="ticket.matrixConf"
      :matrixRoomId="ticket.matrixRoomId"
      :clientNameMap="ticket.nameMap"
      v-if="ticket.hasOwnProperty('notes')"
      class="ticket-message-container"
      roomType="travel"
      :roomId="ticket.travel._id"
    ></ticket-message>
    <div class="tool-container">
      <div class="customizable-display">
        <div
          class="category-element"
          :key="element.id"
          v-for="(element, elementIndex) in customDisplay"
        >
          <p class="element-title" :style="{ backgroundColor: element.color }">
            {{ element.title }}
          </p>
          <div class="button-container">
            <button
              type="button"
              @click="customDisplay.splice(elementIndex, 1)"
            >
              X
            </button>
          </div>
          <hr />
          <div
            class="element-properties"
            :key="property.id"
            v-for="(property, propertyIndex) in element.properties"
          >
            <span>{{ property.title + " : " }}</span>
            <router-link
              v-if="property.link != '' && property.normal == false"
              :to="property.link"
              target="_blank"
              exact
              >{{ property.name }}</router-link
            >
            <a
              v-else-if="property.link != '' && property.normal == true"
              :href="property.link"
              target="_blank"
              >{{ property.name }}</a
            >
            <span v-else>{{ property.name }}</span>
            <div class="button-container">
              <button
                type="button"
                class="remove-element-button"
                @click="removeProperty(elementIndex, propertyIndex)"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="ticket-info-display">
        <div class="tool-bar-item" v-for="tool in toolbar" :key="tool.id">
          <div
            class="toolbar-context"
            @click="
              tool.showContent = !tool.showContent;
              tool.deploy == '>' ? (tool.deploy = 'v') : (tool.deploy = '>');
            "
          >
            <button class="toolbar-deploy">
              {{ tool.deploy }}
            </button>
            <span class="toolbar-context-text">{{ tool.context }}</span>
          </div>
          <div class="toolbar-content" v-show="tool.showContent">
            <button
              class="toolbar-content-item"
              v-for="item in tool.content"
              @click="onAction(item.click)"
              :key="item.key"
              :style="item.style"
              v-show="checkShowItem"
            >
              {{ item.description }}
            </button>
          </div>
        </div>
        <div class="tool-bar-item" v-if="ticket.hasOwnProperty('notes')">
          <div
            class="toolbar-context"
            @click="
              note.showContent = !note.showContent;
              note.deploy == '>' ? (note.deploy = 'v') : (note.deploy = '>');
            "
          >
            <button class="toolbar-deploy">
              {{ note.deploy }}
            </button>
            <span class="toolbar-context-text">{{ note.context }}</span>
          </div>
          <div class="toolbar-content" v-show="note.showContent">
            <textarea
              class="toolbar-notes"
              v-model="ticket.notes"
              placeholder="You can write notes in this area"
              @blur="updateNotes()"
            ></textarea>
          </div>
        </div>
        <div
          class="tool-bar-item"
          v-if="ticket.hasOwnProperty('travel') && ticket.travel.todoList"
        >
          <div
            class="toolbar-context"
            @click="
              ticket.travel.todoList.showContent =
                !ticket.travel.todoList.showContent;
              ticket.travel.todoList.deploy == '>'
                ? (ticket.travel.todoList.deploy = 'v')
                : (ticket.travel.todoList.deploy = '>');
            "
          >
            <button class="toolbar-deploy">
              {{ ticket.travel.todoList.deploy }}
            </button>
            <span class="toolbar-context-text">{{
              ticket.travel.todoList.context
            }}</span>
          </div>
          <div
            class="toolbar-content"
            v-show="ticket.travel.todoList.showContent"
          >
            <button
              class="newTask"
              @click="
                ticket.travel.todoList.content.push({
                  agent: $store.getters.name,
                  startDate: new Date(),
                  closed: false,
                  note: '',
                  show: false,
                  title: 'task title',
                });
                updateTodo();
              "
            >
              +
            </button>
            <br />

            <br v-show="ticket.travel.todoList.content.length > 0" />
            <div class="open-todo">
              <div
                v-for="(task, taskid) in ticket.travel.todoList.content"
                :key="task.id"
                v-show="!task.closed"
              >
                <span class="task-tick-border">
                  <span
                    v-html="$twemoji.parse('✔️')"
                    class="open-task-tick"
                    @click="
                      ticket.travel.todoList.content[taskid].closed = true;
                      ticket.travel.todoList.content[taskid].endDate =
                        new Date();
                      updateTodo();
                    "
                  >
                  </span
                ></span>
                <input
                  type="text"
                  v-model="task.title"
                  class="task-title"
                  @blur="updateTodo()"
                />
                <button
                  @click="
                    ticket.travel.todoList.content[taskid].show =
                      !ticket.travel.todoList.content[taskid].show
                  "
                  class="task-show-button"
                >
                  {{ task.show ? "-" : "+" }}
                </button>
                <br />
                <span class="task-information">{{
                  new Date(task.startDate).toDateString() + " - " + task.agent
                }}</span>
                <br />
                <textarea
                  class="task-notes"
                  v-show="task.show"
                  v-model="task.note"
                  @blur="updateTodo()"
                >
                </textarea>
              </div>
            </div>
            <br v-show="ticket.travel.todoList.content.length > 0" />
            <hr v-show="ticket.travel.todoList.content.length > 0" />
            <br v-show="ticket.travel.todoList.content.length > 0" />
            <div class="closed-todo">
              <div
                v-for="(task, taskid) in ticket.travel.todoList.content"
                :key="task.id"
                v-show="task.closed"
              >
                <span class="task-tick-border"
                  ><span
                    v-html="$twemoji.parse('✔️')"
                    class="closed-task-tick"
                    @click="
                      ticket.travel.todoList.content[taskid].closed = false;
                      delete ticket.travel.todoList.content[taskid].endDate;
                      updateTodo();
                    "
                  ></span
                ></span>
                <input
                  type="text"
                  v-model="task.title"
                  class="task-title"
                  disabled="true"
                />
                <button
                  @click="
                    ticket.travel.todoList.content[taskid].show =
                      !ticket.travel.todoList.content[taskid].show
                  "
                  class="task-show-button"
                >
                  {{ task.show ? "-" : "+" }}
                </button>
                <br />
                <span class="task-information">{{
                  "closed on " +
                  new Date(task.endDate).toDateString() +
                  " - " +
                  task.agent
                }}</span>
                <br />
                <textarea
                  class="task-notes"
                  v-show="task.show"
                  v-model="task.note"
                  disabled="true"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="email-container"
      v-if="showEmail"
      @click.self="showEmail = false"
    >
      <email
        class="emailForm"
        @emailSent="showEmail = false"
        :currentTicketId="ticket._id"
      ></email>
    </div>
    <div
      class="email-container"
      v-if="showDatabaseSearch"
      @click.self="showDatabaseSearch = false"
    >
      <database
        class="database-search-pop"
        :location="location"
        :activity="activity"
      ></database>
    </div>

    <modal
      name="topology-modal"
      :scrollable="true"
      :adaptive="true"
      width="90%"
      :maxWidth="600"
      height="auto"
      :maxHeight="600"
      classes="modal"
      @closed="cancelClass"
    >
      <div class="modal-header">
        <p v-if="!classSelected">Ticket Category</p>
        <p v-if="classSelected" class="header-selected">
          {{ typology[0][selectedTypo.class] }}
        </p>
        <p v-if="classSelected" class="header-back" v-on:click="cancelClass">
          ◀ Back
        </p>
      </div>
      <div class="modal-body">
        <div v-if="!classSelected">
          <div v-for="(element, index) in typology[0]" :key="element">
            <p class="class" v-on:click="openClass(index)">{{ element }}</p>
          </div>
        </div>
        <div
          v-if="
            (classSelected && !subClassSelected) ||
            (classSelected &&
              subClassSelected &&
              typology[2][selectedTypo.class].length <= 0)
          "
        >
          <div
            v-for="(sub, index) in typology[1][selectedTypo.class]"
            :key="sub"
          >
            <p class="class" v-on:click="selectSub(index)">{{ sub }}</p>
          </div>
          <label class="other-input" for="other-input">Autre : </label>
          <input
            id="other-input"
            class="other-input"
            type="text"
            placeholder="Other..."
            v-model="selectedTypo.custom"
          /><button v-on:click="validateCustomSub">Valider</button>
        </div>
        <div
          v-if="
            classSelected &&
            subClassSelected &&
            typology[2][selectedTypo.class].length > 0
          "
        >
          <div
            v-for="(final, index) in typology[2][selectedTypo.class]"
            :key="final"
          >
            <p class="class" v-on:click="selectFinal(index)">{{ final }}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <p>
          Selectioné :
          <span class="selected-typo" v-if="selectedTypo.class !== ''">{{
            typology[0][selectedTypo.class]
          }}</span
          ><span
            v-if="
              (selectedTypo.subclass !== '' && selectedTypo.custom == '') ||
              selectedTypo.custom !== ''
            "
          >
            ➤
          </span>
          <span
            class="selected-typo selected-sub"
            v-if="selectedTypo.subclass !== '' && selectedTypo.custom == ''"
          >
            {{ typology[1][selectedTypo.class][selectedTypo.subclass] }}</span
          >
          <span
            class="selected-typo selected-sub"
            v-if="selectedTypo.custom !== ''"
          >
            {{ selectedTypo.custom }}</span
          >
        </p>
        <p class="selected-typo" v-if="selectedTypo.finalclass !== ''">
          {{ typology[2][selectedTypo.class][selectedTypo.finalclass] }}
        </p>
        <button
          class="close-ticket"
          v-if="
            selectedTypo.class !== '' &&
            (selectedTypo.subclass !== '' || selectedTypo.custom !== '') &&
            (selectedTypo.finalclass !== '' ||
              typology[2][selectedTypo.class].length <= 0)
          "
          v-on:click="closeTicket"
        >
          Close Ticket
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import TicketMessage from "./TicketMessages.vue";
import HandleApi from "../apiHandle";
import Email from "./Email.vue";
import Database from "./Database.vue";

export default {
  name: "TicketDisplay",
  components: {
    TicketMessage,
    Email,
    Database,
  },
  data() {
    return {
      ticket: { history: [] },
      showEmail: false,
      location: false,
      activity: false,
      showDatabaseSearch: false,
      customDisplay: [],
      note: {
        context: "Notes",
        showContent: false,
        deploy: ">",
      },
      evaluation: { notation: 6, comment: "" },
      classSelected: false,
      subClassSelected: false,
      selectedTypo: {
        class: "",
        subclass: "",
        custom: "",
        finalclass: "",
      },
      typology: [
        [
          "Lancement service",
          "Demande renseignements",
          "Demande de service",
          "Demande de réservation",
          "Demande de confirmation",
          "Interrogation services TA",
          "Conversation",
          "Situation d'urgence",
          "Relance",
          "Réclamation/retour Agences",
        ],
        [
          ["Prise de contact et ouverture du service"],
          [
            "Transport",
            "Loisir",
            "Restauration",
            "Activités",
            "Pratique",
            "Santé",
            "Culture",
            "Hebergement",
            "Boutique",
            "COVID",
            "Demandes insolites (tatouage etc...)",
            "Climat",
            "Us et coutumes",
            "Demandes illicites (prostitution, drogue etc...)",
          ],
          ["Idées pour demande en mariage ", "Pour cadeaux surprise"],
          [
            "Restaurant",
            "Taxi voiture",
            "Taxi moto",
            "Uber",
            "Limousine ou voiture de luxe",
            "Navette",
            "Avion",
            "Train",
            "Hélocoptère",
            "Vélo",
            "Pass-Transport",
            "Spectacle (concert, cinéma, évènement sportif...)",
            "Bateau",
            "Montgolfière",
            "Demandes insolites",
            "Scooter",
            "Guide local",
            "Activités",
            "Hebergement (hotel, maison d'hôte, auberge...)",
            "Loisirs",
          ],
          [
            "Restaurant",
            "Taxi voiture",
            "Taxi moto",
            "Uber",
            "Limousine ou voiture de luxe",
            "Navette",
            "Avion",
            "Train",
            "Hélocoptère",
            "Vélo",
            "Pass-Transport",
            "Spectacle (concert, cinéma, évènement sportif...)",
            "Bateau",
            "Montgolfière",
            "Demandes insolites",
            "Scooter",
            "Guide local",
            "Activités",
            "Hebergement (hotel, maison d'hôte, auberge...)",
            "Loisirs",
          ],
          [
            "Qu'inclus le service",
            "Si on peut faire les réservations pour eux",
            "Si nous réservons comment payer et est ce qu'il y a un coût supl.",
            "Si c'est un robot ou des humain derrière",
          ],
          [
            "Partage de photos",
            "Partage d'expérience ",
            "Proposition du client d'un resto ou activtié découverte par hasard",
          ],
          [
            "Perte de papiers d'identité ou passeport",
            "Perte de clés",
            "Perte de moyens de paiement",
            "Attentat",
            "Problème climatique",
            "Panne de voiture",
            "Perdu",
            "Oubli d'un objet ou autre (dans l'hôtel, avion...)",
            "Retard pour un resto ou un hôtel",
            "Raté l'avion",
            "Crise sanitaire",
            "Est ce que c'est bien inclus dans leur package",
            "Problème de chambre ou d'hôtel",
            "Agression",
            "Problème de santé",
            "Décès",
            "Rapatriement",
          ],
          ["Prise de nouvelles", "Choix non determiné", "Suggestion, conseil"],
          [
            "Problème sur une réservation",
            "Retour sur une insatisfaction client ",
            "Accessibilité à la conciergerie",
          ],
        ],
        [
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [],
          [],
          [
            "Prise en compte en moins de 5 minutes",
            "Prise en compte en moins de 10 minutes",
            "Prise en compte en moins de 15 minutes",
            "Prise en compte en mois de 20 minutes",
            "Prise en compte supérieure à 20 minutes",
          ],
          [
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
        ],
      ],
      toolbar: [
        {
          showContent: true,
          context: "Information",
          deploy: ">",
          content: [
            {
              description: "Client Profile",
              style: { backgroundColor: "#009fbc" },
              click: "clientProfile",
              show: true,
            },
            {
              description: "Travel Information",
              style: { backgroundColor: "#aa1853" },
              click: "travelInformation",
              show: true,
            },
            {
              description: "Agency Information",
              style: { backgroundColor: "#008467" },
              click: "agencyInformation",
              show: true,
            },
          ],
        },
        {
          showContent: true,
          context: "Ticket Actions",
          deploy: ">",
          content: [
            {
              description: "Close Ticket",
              style: { backgroundColor: "#aa1853" },
              click: "closeTicket",
              show: true,
            },
          ],
        },
        /*{
          showContent: false,
          context: "Search Database",
          deploy: ">",
          content: [
            {
              description: "Generic Search",
              style: { backgroundColor: "#008467" },
              click: "genericSearch",
              show: true,
            },
            {
              description: "City Search",
              style: { backgroundColor: "#009fbc" },
              click: "citySearch",
              show: true,
            },
            {
              description: "Activity Search",
              style: { backgroundColor: "#9ad3d1" },
              click: "activitySearch",
              show: true,
            },
          ],
        },
        {
          showContent: false,
          context: "Communication",
          deploy: ">",
          content: [
            {
              description: "Send Email",
              style: { backgroundColor: "#009fbc" },
              click: "sendEmail",
              show: true,
            },
          ],
        },*/
      ],
    };
  },
  computed: {
    checkShowItem: function (item) {
      if (item.description == "Travel Information") {
        if (this.showTravelButton) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    computeStyleStatus: function () {
      switch (this.ticket.status) {
        case "pending":
          return "#008467";
        case "answer":
          return "#009fbc";
        case "transfer":
          return "#00909f";
        default:
          return "#aa1853";
      }
    },
    niceHistoryDisplay: function () {
      let res = [];
      this.ticket.history.forEach((element) => {
        if (element.name == "status") {
          element.color = "#008467";
          element.name = "Status update: " + element.information.newStatus;
        } else if (element.name == "Email") {
          element.color = "#009fbc";
          element.name = "Email to: " + element.information.to[0];
        } else if (element.name == "Search") {
          element.color = "#034c57";
          element.name = "Search for: " + element.information.keywords;
        } else if (element.name == "Start Handling") {
          element.color = "#57D186";
        }
        res.push(element);
      });
      return res;
    },
  },
  async created() {
    try {
      let ticket = await HandleApi.getTicketInformation(this.$route.params.id);
      let nameMap = {};
      for (let i = 0; i < ticket.clients.length; i++) {
        if (ticket.clients[i].hasOwnProperty("messageApp")) {
          for (let j = 0; j < ticket.clients[i].messageApp.length; j++) {
            switch (ticket.clients[i].messageApp[j].app) {
              case "telegram":
                nameMap[
                  "@telegram_" +
                    ticket.clients[i].messageApp[j].identifier +
                    ":" +
                    ticket.matrixConf.userId.substring(
                      ticket.matrixConf.userId.indexOf(":") + 1
                    )
                ] = ticket.clients[i].name + " " + ticket.clients[i].surname;
                break;
              case "matrix":
                nameMap[ticket.clients[i].messageApp[j].identifier] =
                  ticket.clients[i].name + " " + ticket.clients[i].surname;
                break;
            }
          }
        }
      }
      if (ticket.travel) {
        if (ticket.travel.todoList) {
          for (let i = 0; i < ticket.travel.todoList.length; i++) {
            ticket.travel.todoList[i].show = false;
          }
          ticket.travel["todoList"] = {
            showContent: false,
            context: "to-do",
            deploy: ">",
            content: ticket.travel.todoList,
          };
        } else {
          ticket.travel["todoList"] = {
            showContent: false,
            context: "to-do",
            deploy: ">",
            content: [],
          };
        }
      }

      ticket.history = ticket.history
        .sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        })
        .reverse();
      ticket["nameMap"] = nameMap;
      if (!ticket.hasOwnProperty("notes")) {
        ticket.notes = "";
      }
      if (ticket.hasOwnProperty("evaluation")) {
        this.evaluation = ticket.evaluation;
      }
      this.ticket = ticket;
    } catch (err) {
      window.console.log(err);
    }
  },
  methods: {
    openClass: function (id) {
      this.classSelected = true;
      this.selectedTypo.class = id;
    },
    cancelClass: function () {
      this.classSelected = false;
      this.subClassSelected = false;
      this.selectedTypo.class = "";
      this.selectedTypo.subclass = "";
      this.selectedTypo.custom = "";
      this.selectedTypo.finalclass = "";
    },
    selectSub: function (value) {
      this.subClassSelected = true;
      this.selectedTypo.custom = "";
      this.selectedTypo.subclass = value;
    },
    validateCustomSub: function () {
      this.subClassSelected = true;
    },
    selectFinal: function (value) {
      this.selectedTypo.finalclass = value;
    },
    onAction: function (event) {
      switch (event) {
        // Information
        case "clientProfile":
          this.clientProfile();
          break;
        case "travelInformation":
          this.travelInformation();
          break;
        case "agencyInformation":
          this.agencyInformation();
          break;
        //Ticket Actions
        case "closeTicket":
          this.closeTicketModal();
          break;
        //Search Database
        case "genericSearch":
          this.genericSearch();
          break;
        case "citySearch":
          this.citySearch();
          break;
        case "activitySearch":
          this.activitySearch();
          break;
        //Communication
        case "sendEmail":
          this.sendEmail();
          break;
      }
    },
    clientProfile: async function () {
      try {
        var self = this;
        this.ticket.clients.forEach(async function (element) {
          let temp = await HandleApi.getClientInformationMinified(element._id);
          self.customDisplay.push({
            title: temp.client.name + " " + temp.client.surname,
            color: "#009fbc",
            properties: [
              {
                title: "profile",
                name: "link",
                link: "/client/" + temp.client._id,
                normal: false,
              },
              {
                title: "gender",
                name: temp.client.gender,
                link: "",
                normal: true,
              },
              {
                title: "language",
                name: temp.client.language,
                link: "",
                normal: true,
              },
            ],
          });
        });
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
    agencyInformation: async function () {
      try {
        let temp = await HandleApi.getAgencyInformationMinified(
          this.ticket.tourAgency._id
        );
        let properties = [
          {
            title: "page",
            name: "link",
            link: "/agency/" + temp.agency._id,
            normal: false,
          },
        ];
        if (temp.agency.hasOwnProperty("phone")) {
          properties.push({
            title: "phone",
            name: temp.agency.phone,
            link: "",
            normal: true,
          });
        }
        if (temp.agency.hasOwnProperty("emergencyPhone")) {
          properties.push({
            title: "emergency phone",
            name: temp.agency.emergencyPhone,
            link: "",
            normal: true,
          });
        }
        /*if (temp.agency.hasOwnProperty("website")) {
          properties.push({
            title: "website",
            name: temp.agency.website,
            link: temp.agency.website,
            normal: true,
          });
        }*/
        if (temp.agency.hasOwnProperty("email")) {
          properties.push({
            title: "email",
            name: temp.agency.email,
            link: "",
            normal: true,
          });
        }
        this.customDisplay.push({
          title: temp.agency.name,
          properties: properties,
          color: "#008467",
        });
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
    travelInformation: async function () {
      try {
        let temp = await HandleApi.getTravelInformationMinified(
          this.ticket.travel._id
        );
        let properties = [
          {
            title: "page",
            name: "link",
            link: "/travel/" + temp.travel._id,
            normal: false,
          },
          {
            title: "start",
            name: new Date(temp.travel.startDate).toDateString(),
            link: "",
            normal: true,
          },
          {
            title: "end",
            name: new Date(temp.travel.endDate).toDateString(),
            link: "",
            normal: true,
          },
          {
            title: "place",
            name: temp.travel.place,
            link: "",
            normal: true,
          },
          {
            title: "ghost option",
            name: temp.travel.watchConversation,
            link: "",
            normal: true,
          },
          /*{
            title: "own app option",
            name: temp.travel.ownApp,
            link: "",
            normal: true,
          },*/
        ];
        if (temp.travel.hasOwnProperty("roadmap")) {
          properties.push({
            title: "roadmap",
            name: "link",
            link: temp.travel.roadmap,
            normal: true,
          });
        }
        if (temp.travel.files.length) {
          for (const fileUrl of temp.travel.files) {
            properties.push({
              title: "file",
              name: fileUrl
                .split("/")
                .at(-1)
                .split("?")[0]
                .replaceAll("%20", " "),
              link: fileUrl,
              normal: true,
            });
          }
        }
        this.customDisplay.push({
          title: temp.travel.name,
          properties: properties,
          color: "#aa1853",
        });
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
    closeTicket: async function () {
      let typology = {
        class: this.selectedTypo.class,
        subClass: this.selectedTypo.subclass,
        final: this.selectedTypo.finalclass,
      };
      if (this.selectedTypo.custom !== "") {
        typology.subClass = this.selectedTypo.custom;
      }
      if (this.selectedTypo.finalclass === "") {
        typology.final = "none";
      }
      await HandleApi.putTicket(this.$route.params.id, {
        status: "closed",
        endDate: new Date(),
        typology,
      }).catch((err) =>
        this.$store.dispatch("push_error_message", err.response.data)
      );
      this.ticket.status = "closed";
      this.$modal.hide("topology-modal");
    },
    closeTicketModal: function () {
      this.$modal.show("topology-modal");
    },
    sendEmail: function () {
      this.showEmail = true;
    },
    genericSearch() {
      this.location = false;
      this.activity = false;
      this.showDatabaseSearch = true;
    },
    citySearch() {
      this.location = true;
      this.activity = false;
      this.showDatabaseSearch = true;
    },
    activitySearch() {
      this.location = false;
      this.activity = true;
      this.showDatabaseSearch = true;
    },
    updateNotes: async function () {
      await HandleApi.putTicket(this.$route.params.id, {
        notes: this.ticket.notes,
      }).catch((err) =>
        this.$store.dispatch("push_error_message", err.response.data)
      );
    },
    saveEvaluation: async function () {
      try {
        await HandleApi.putTicket(this.$route.params.id, {
          evaluation: this.evaluation,
        });
        this.$store.dispatch("push_notification_message", "evaluation saved");
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
    removeProperty(elementIndex, propertyIndex) {
      this.customDisplay[elementIndex].properties.splice(propertyIndex, 1);
    },
    async updateTodo() {
      try {
        await HandleApi.updateTodo(this.ticket.travel._id, {
          todoList: this.ticket.travel.todoList.content,
        });
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data || err);
      }
    },
  },
};
</script>

<style>
.ticket-display-container {
  display: grid;
  grid-template-columns: 300px 1fr 500px;
}

.ticket-message-container {
  margin-left: 10px;
}

.toolbar {
  margin-left: 10px;
}

.toolbar-context {
  height: 60px;
  line-height: 60px;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.toolbar-deploy {
  margin-right: 10px;
  font-size: 20px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: 30px;
}

.toolbar-context:hover {
  cursor: pointer;
}

.toolbar-context-text {
  font-size: 20px;
}

.toolbar-content {
  padding: 10px;
  border: 1px solid lightgrey;
  border-top: none;
  border-radius: 5px;
}

.toolbar-content-item {
  height: 30px;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.toolbar-notes {
  border: 1px solid lightgrey;
  width: 100%;
  overflow: auto;
  outline: none;
  resize: vertical;
  max-height: 300px;
}

.errorDisplay {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  margin: 0 auto;
  border-radius: 10px;
  height: 27px;
  padding: 10px;
  padding-top: 15px;
  font-size: 17px;
}
.closeError {
  margin-left: 20px;
  border: 2px solid #ecb2b2;
  padding: 3px;
}

.closeError:hover {
  border: 2px solid red;
  cursor: pointer;
}

.email-container {
  position: absolute;
  height: calc(100% + 80px);
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  top: 0px;
  left: 0;
}

.emailForm {
  width: 70%;
  height: 70%;
  margin: auto;
  margin-top: 10%;
  margin-left: 15%;
  z-index: 101;
  background-color: white;
}

.database-search-pop {
  width: 70%;
  height: 70%;
  margin: auto;
  margin-top: 10%;
  margin-left: 15%;
  z-index: 101;
  background-color: white;
  padding: 10px;
  overflow-y: scroll;
}

.ticket-information-generic {
  padding-left: 10px;
}

.ticket-status-display {
  height: 30px;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: bold;
  display: inline-block;
  margin: 0;
  background-color: black;
}

.ticket-information-generic label {
  margin-right: 5px;
}

.ticket-information-generic input[type="range"] {
  margin-top: 5px;
}

.ticket-information-generic textarea {
  border: 1px solid lightgrey;
  width: 100%;
  overflow: auto;
  outline: none;
  resize: vertical;
  max-height: 300px;
}

.save-evaluation-button {
  height: 30px;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: bold;
  display: inline-block;
  margin: 0;
  background-color: #008467;
  float: right;
}

.customizable-display {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid darkgrey;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  resize: vertical;
}

.customizable-display .category-element {
  display: inline-block;
  text-align: center;
  margin: 10px;
}

.customizable-display .element-title {
  padding: 10px;
  border-radius: 10px;
  color: white;
  display: inline-block;
}

.customizable-display .button-container {
  display: inline-block;
  margin-left: 10px;
}

.customizable-display button {
  color: white;
  background-color: lightgrey;
  font-size: 10px;
  padding: 5px;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 20px;
}

.customizable-display button:hover {
  background-color: darkgrey;
  cursor: pointer;
}

.ticket-history {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.newTask {
  float: right;
  color: white;
  border: none;
  background-color: #00909f;
  font-size: 20px;
  font-weight: bold;
}

.task-tick-border {
  border: solid 2px lightgrey;
}

.open-task-tick {
  opacity: 0;
}

.open-task-tick:hover {
  opacity: 1;
  cursor: pointer;
}

.closed-task-tick {
  opacity: 1;
}

.closed-task-tick:hover {
  opacity: 0;
  cursor: pointer;
}

.task-show-button {
  background-color: white;
  border: 2px solid white;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: bold;
}

.task-show-button:hover {
  cursor: pointer;
  border-color: black;
}

.task-title {
  border: 2px solid white;
  padding: 5px;
  border-radius: 5px;
}

.task-title:hover {
  border-color: lightgrey;
}

.closed-todo .task-title {
  text-decoration: line-through;
}

.task-notes {
  height: 100px;
  width: 90%;
}

.task-information {
  font-size: 12px;
  color: rgb(100, 100, 100);
}
.modal {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.modal p {
  margin: 5px 10px 0 10px;
}
.modal-header * {
  margin: 0;
}
.modal-header {
  background: #e8e8e8;
  width: 100%;
  position: relative;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.modal-header p {
  font-size: 115%;
}
.modal-body {
  overflow-y: scroll;
}
.modal-footer {
  background: #e8e8e8;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  box-shadow: -2px -2px -2px -1px rgba(0, 0, 0, 0.2);
}
.header-back {
  display: block;
  position: absolute;
  right: 20px;
  top: 0;
  font-weight: bold;
  font-size: 115%;
  cursor: pointer;
}
.other-input {
  margin: 5px 0 5px 10px;
}
.close-ticket {
  justify-self: center;
  align-self: center;
  margin: 5px 0;
  height: 30px;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: bold;
  background-color: rgb(170, 24, 83);
  cursor: pointer;
}
.class {
  margin: 5px 15px;
  cursor: pointer;
}
.class::before,
.other-input::before {
  content: "> ";
  font-weight: bold;
}
.selected-typo {
  font-weight: bold;
  font-size: 115%;
}
.selected-sub {
  text-decoration: underline;
}
label {
  font-weight: bold;
}
</style>
